<template>
  <div>
    <ViewTemplateReportList :panel="panel" :reports="reports" />
  </div>
</template>

<script>
import ViewTemplateReportList from "@nixweb/nixloc-ui/src/component/template/ViewTemplateReportList";

export default {
  name: "ReportBilletView",
  components: { ViewTemplateReportList },
  data() {
    return {
      panel: {
        module: "Boleto Bancário",
        title: "Relatório",
        showFilter: false,
        showSearch: true,
        showButtons: false,
      },
      reports: {
        default: [
          {
            name: "Boleto",
            description: "Filtre boletos por (emitido, cancelado, recebidos) e outros critérios...",
            routeName: "_reportBillet",
          },
        ],
        saved: [],
      },
    };
  },
};
</script>
